import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  Button,
  DrawerTrigger,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@ui/components';
import { useMobile } from '@ui/hooks';
import { SubscriptionContent } from './subscription-content';
import {
  useGetCreatorProductsQuery,
  useGetUserSubscriptionsQuery,
} from '@lib/redux/api/monetization-api';
import {
  creatorProductAdapter,
  creatorProductSelector,
  ICreatorProductQueryParam,
  IUserSubscriptionQueryParam,
  userSubscriptionAdapter,
  userSubscriptionSelector,
} from '@monetization/service';
import { useAppSelector } from '@lib/redux/store';
import { twMerge } from 'tailwind-merge';
import { SubscriptionManagement } from './subscription-management';
import { LoaderProvider } from '@ui/contexts';
import { useSearchParams, useRouter } from 'next/navigation';
import { useAuthSession } from '@lib/hooks/use-auth-session';

export interface ISubscriptionTriggerProps {
  product_ids?: string[];
  children?: React.ReactNode;
  creatorName: string;
  heroLabel?: string;
  creator_id: string;
  triggeredFrom: 'creator-profile' | 'prize-badge' | 'content';
  initialOpen?: boolean;
  onDialogClosed?: VoidFunction;
}
export const SubscriptionTrigger = ({
  product_ids,
  children,
  creatorName,
  creator_id,
  triggeredFrom,
  onDialogClosed,
  heroLabel,
  initialOpen,
}: ISubscriptionTriggerProps) => {
  const { isAuthenticated } = useAuthSession();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const [open, setOpen] = useState(initialOpen || false);
  const isMobile = useMobile();
  const drawerRef = useRef<HTMLDivElement>(null);
  const [query] = useState<IUserSubscriptionQueryParam>({
    product_ids,
    user_id: user.id,
    creator_id,
    page: 1,
    pageSize: 10,
  });
  const { data, isLoading, refetch } = useGetUserSubscriptionsQuery(
    { ...query, user_id: user.id, creator_id, product_ids },
    {
      skip: !user?.id || !creator_id || !isAuthenticated,
      selectFromResult: ({ data, ...params }) => {
        return {
          data: {
            ...data,
            user_subscriptions: userSubscriptionSelector.selectAll(
              data ? data.user_subscriptions : userSubscriptionAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
      refetchOnMountOrArgChange: true,
    },
  );
  const [creatorProductQuery] = useState<ICreatorProductQueryParam>({
    product_ids,
    creator_id,
    page: 1,
    pageSize: 10,
  });
  const { data: creatorProductData } = useGetCreatorProductsQuery(
    { ...creatorProductQuery, creator_id },
    {
      skip: !creator_id,
      selectFromResult: ({ data, ...params }) => ({
        data: {
          ...data,
          products: creatorProductSelector.selectAll(
            data ? data.products : creatorProductAdapter.getInitialState(),
          ),
        },
        ...params,
      }),
      refetchOnMountOrArgChange: true,
    },
  );
  const products = useMemo(() => creatorProductData?.products || [], [data]);
  const creatorHasProducts = useMemo(() => products.length > 0, [products]);
  const subscriptions = useMemo(() => data?.user_subscriptions || [], [data]);
  const hasActiveOrCancelledPendingSubscription = useMemo(() => {
    const hasSubscription = subscriptions.length > 0;
    const hasActiveSubscription = hasSubscription && subscriptions[0].status === 'active';
    const hasCancelledPendingSubscription =
      hasSubscription && subscriptions[0].status === 'cancelled_pending';
    return hasActiveSubscription || hasCancelledPendingSubscription;
  }, [subscriptions]);

  const onClose = (reason: 'cancel' | 'completion') => {
    setOpen(false);
    refetch();

    if (onDialogClosed && reason === 'completion') {
      onDialogClosed();
    }

    const url = new URL(window.location.href);
    url.searchParams.delete('open-subscription-modal');
    window.history.pushState({}, '', url);
  };

  const onRefetchSubscriptions = () => refetch();

  const openStateValidation = useMemo(() => {
    if (!creatorHasProducts && !hasActiveOrCancelledPendingSubscription) {
      return false;
    } else {
      return open;
    }
  }, [open, creatorHasProducts, hasActiveOrCancelledPendingSubscription]);

  const onOpenChangeEvent = useCallback(
    (newOpenState: boolean) => {
      setOpen(newOpenState);
      if (paymentSuccess && onDialogClosed) {
        onDialogClosed();
      }

      const url = new URL(window.location.href);
      if (newOpenState) {
        url.searchParams.set('open-subscription-modal', 'true');
      } else {
        url.searchParams.delete('open-subscription-modal');
      }
      window.history.pushState({}, '', url);
    },
    [paymentSuccess, onDialogClosed],
  );

  const onPaymentSuccess = () => setPaymentSuccess(true);

  if (isMobile) {
    return (
      <LoaderProvider>
        <Drawer snapPoints={[1]} open={openStateValidation} onOpenChange={onOpenChangeEvent}>
          <DrawerTrigger
            asChild
            disabled={!creatorHasProducts && !hasActiveOrCancelledPendingSubscription}>
            {children ||
              ((creatorHasProducts || hasActiveOrCancelledPendingSubscription) && (
                <Button
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="primary"
                  size="small"
                  className={twMerge(
                    'font-semibold px-4 py-2 min-w-fit text-xs bg-bgBlack hover:bg-bgBlack',
                    hasActiveOrCancelledPendingSubscription &&
                      'bg-bgBlack/20 hover:bg-bgBlack/20 text-black',
                  )}>
                  {hasActiveOrCancelledPendingSubscription ? 'Supporting' : 'Support'}
                </Button>
              ))}
          </DrawerTrigger>
          <DrawerContent
            ref={drawerRef}
            hideDragHandle
            className="scrollbar-none bg-white focus-visible:outline-none mt-0 rounded-t-lg sm:rounded-lg p-1.5 m-0 h-fit max-h-[99vh] overflow-hidden">
            <DialogTitle className="p-0 m-0 h-0" />
            <DrawerDescription className="p-0 m-0 h-0" />
            {hasActiveOrCancelledPendingSubscription && open && (
              <SubscriptionManagement
                canShowCancelSubscriptionBtn={false}
                canShowManageSubscriptionBtn
                onRefetchSubscriptions={onRefetchSubscriptions}
                userSubscription={subscriptions[0]}
                onClose={onClose}
                onPaymentSuccess={onPaymentSuccess}
              />
            )}
            {!hasActiveOrCancelledPendingSubscription && open && (
              <SubscriptionContent
                onRefetchSubscriptions={onRefetchSubscriptions}
                creator_id={creator_id}
                onClose={onClose}
                creatorName={creatorName}
                product_ids={product_ids}
                triggeredFrom={triggeredFrom}
                onPaymentSuccess={onPaymentSuccess}
                heroLabel={heroLabel}
              />
            )}
          </DrawerContent>
        </Drawer>
      </LoaderProvider>
    );
  }

  return (
    <LoaderProvider>
      <Dialog open={openStateValidation} onOpenChange={onOpenChangeEvent}>
        <DialogTrigger
          asChild
          className="space-y-0"
          disabled={!creatorHasProducts && !hasActiveOrCancelledPendingSubscription}>
          {children ||
            ((creatorHasProducts || hasActiveOrCancelledPendingSubscription) && (
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                type="primary"
                size="small"
                className={twMerge(
                  'font-semibold px-4 py-2 min-w-fit text-xs bg-bgBlack hover:bg-bgBlack',
                  hasActiveOrCancelledPendingSubscription &&
                    'bg-bgBlack/20 hover:bg-bgBlack/20 text-black',
                )}>
                {hasActiveOrCancelledPendingSubscription ? 'Supporting' : 'Support'}
              </Button>
            ))}
        </DialogTrigger>
        <DialogContent
          ref={drawerRef}
          className="scrollbar-none bg-white rounded-lg sm:rounded-lg p-1.5 m-0 max-h-[95vh] overflow-hidden"
          showCloseButton={false}>
          <DialogHeader className="space-y-0 h-0 hidden">
            <DialogTitle className="p-0 m-0 h-0" />
            <DialogDescription className="p-0 m-0 h-0" />
          </DialogHeader>
          {hasActiveOrCancelledPendingSubscription && open && (
            <SubscriptionManagement
              canShowCancelSubscriptionBtn={false}
              canShowManageSubscriptionBtn
              onRefetchSubscriptions={onRefetchSubscriptions}
              userSubscription={subscriptions[0]}
              onClose={onClose}
              onPaymentSuccess={onPaymentSuccess}
            />
          )}
          {!hasActiveOrCancelledPendingSubscription && open && (
            <SubscriptionContent
              onRefetchSubscriptions={onRefetchSubscriptions}
              creator_id={creator_id}
              onClose={onClose}
              creatorName={creatorName}
              product_ids={product_ids}
              triggeredFrom={triggeredFrom}
              onPaymentSuccess={onPaymentSuccess}
              heroLabel={heroLabel}
            />
          )}
        </DialogContent>
      </Dialog>
    </LoaderProvider>
  );
};
