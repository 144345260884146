import { useMemo } from 'react';
import AvatarGroupPlaceholder from '@assets/avatar-group-placeholder.png';

interface Props {
  creatorName: string;
  heroLabel?: string;
}
export const SubscriptionEnticementHero = ({ creatorName, heroLabel }: Props) => {
  const message = useMemo(() => {
    if (!heroLabel) {
      return {
        title: 'Support Your Creator, Unlock Exclusive Badges',
        content: `By supporting ${creatorName}, you not only help them continue creating amazing content, but you also earn exclusive badges.`,
      };
    }

    const isReserveBadge = heroLabel === 'Reserve Badge';
    return {
      title: `Support Your Creator to ${
        isReserveBadge ? 'Reserve This Badge!' : 'Claim Badge Now!'
      }`,
      content: `By supporting ${creatorName} below, you not only help them continue creating amazing content, but you also get to claim exclusive badges immediately, reserve upcoming badges to claim as soon as they are available, plus much more!\n\nDon't worry, you can still earn this badge by completing the challenge!`,
    };
  }, [creatorName, heroLabel]);

  return (
    <div className="flex flex-col justify-center items-center px-8">
      <h1 className="text-2xl font-bold text-white text-center mb-1">{message.title}</h1>
      <p className="text-center text-white whitespace-pre-line text-sm">
        {message.content.split(creatorName)[0]}
        <span className="font-semibold italic text-pretty text-primary">{creatorName}</span>
        {message.content.split(creatorName)[1]}
      </p>

      <div className="-space-x-4 flex mt-10">
        <img
          src={AvatarGroupPlaceholder.src}
          alt="avatar-group-placeholder"
          style={{ height: 45 }}
        />
      </div>
      <p className="text-center text-white text-xs mt-2 max-w-[300px]">
        Thousands of fans are already showing their support. Show your support, claim your rewards!
      </p>
    </div>
  );
};
