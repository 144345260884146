import { LogoLargeIcon } from '@ui/components/atoms/icon/icon-assets/logo-large';
import React, { useMemo, useState } from 'react';
import { Button } from '@ui/components/ui/button';
import { useGetCreatorProductsQuery } from '@lib/redux/api/monetization-api';
import {
  ICreatorProductQueryParam,
  creatorProductSelector,
  creatorProductAdapter,
  ICreatorProduct,
  IUserSubscription,
} from '@monetization/service';
import { SubscriptionProduct } from './subscription-product';
import { ISubscriptionTriggerProps } from './subscription-trigger';
import { SubscriptionEnticementHero } from './subscription-enticement-hero';
import { CMSSubscriptionEnticementHero } from './CMS-subscription-enticement-hero';
import { SubscriptionProductCard } from './subscription-product-card';

interface Props extends Pick<ISubscriptionTriggerProps, 'triggeredFrom'> {
  creatorName: string;
  heroLabel?: string;
  creator_id: string;
  product_ids?: string[];
  userSubscription: IUserSubscription | null;
  onSelectProduct: (product: ICreatorProduct) => void;
  onClose: (reason: 'cancel' | 'completion') => void;
}
export const SubscriptionContentBase = ({
  creatorName,
  triggeredFrom,
  creator_id,
  product_ids,
  userSubscription,
  heroLabel,
  onSelectProduct,
  onClose,
}: Props) => {
  const [query] = useState<ICreatorProductQueryParam>({ creator_id, page: 1, pageSize: 10 });
  const { data: productData } = useGetCreatorProductsQuery(
    { ...query, creator_id, product_ids },
    {
      skip: !creator_id,
      selectFromResult: ({ data, ...params }) => ({
        data: {
          ...data,
          products: creatorProductSelector.selectAll(
            data ? data.products : creatorProductAdapter.getInitialState(),
          ),
        },
        ...params,
      }),
      refetchOnMountOrArgChange: true,
    },
  );

  const subscriptionPositionInProducts = useMemo(() => {
    const productList = [
      ...productData?.products,
      ...(userSubscription ? [{ ...userSubscription?.creator_product, isActive: true }] : []),
    ];

    return productList
      .sort((a, b) => b.price - a.price)
      .findIndex((p) => p._id === userSubscription?.creator_product._id);
  }, [productData, userSubscription]);

  const products = useMemo(() => {
    const productList = [
      ...productData?.products,
      // ...(userSubscription ? [{ ...userSubscription?.creator_product, isActive: true }] : []),
    ];

    // return productList.sort((a, b) => b.price - a.price);
    return productList;
  }, [productData, userSubscription]);

  return (
    <div className="h-full scrollbar-none overflow-y-auto max-h-[95vh]">
      <div className="bg-[#343840] space-y-6 py-3 pb-8">
        <div className="flex justify-between items-center px-4">
          <div className="h-auto w-32 p-5">
            <LogoLargeIcon fill="#ff600d" />
          </div>
          <Button
            variant="ghost"
            size="sm"
            className="rounded-sm h-9 outline-none text-gray-400 text-sm font-light hover:bg-transparent"
            onClick={() => onClose('cancel')}>
            Close
          </Button>
        </div>

        {triggeredFrom === 'content' ? (
          <CMSSubscriptionEnticementHero creatorName={creatorName} />
        ) : (
          <SubscriptionEnticementHero creatorName={creatorName} heroLabel={heroLabel} />
        )}
      </div>

      <div className="mt-4">
        {userSubscription && (
          <SubscriptionProductCard
            showDescription={false}
            isActive={true}
            onSelectProduct={onSelectProduct}
            tierOrder={subscriptionPositionInProducts + 1}
            key={userSubscription.creator_product._id}
            product={userSubscription.creator_product}
          />
        )}
      </div>

      <div className="bg-white p-4 space-y-4 border border-white">
        <div>
          <p className="font-extrabold text-xl">Choose Your Membership Plan</p>
          {triggeredFrom === 'content' && (
            <p className="text-sm font-light">
              Select a plan below to access this exclusive content instantly.
            </p>
          )}
        </div>
        <div className="space-y-4">
          {products.map((product, index) => (
            <SubscriptionProductCard
              showDescription={false}
              isActive={product.isActive}
              onSelectProduct={onSelectProduct}
              tierOrder={index + 1}
              key={product._id}
              product={product}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
