import React, { useMemo, useState } from 'react';
import { SubscriptionContentBase } from './subscription-content-base';
import { SubscriptionProductSelected } from './subscription-product-selected';
import {
  ICreatorProduct,
  ICreatorProductQueryParam,
  IUserSubscription,
  userSubscriptionAdapter,
  userSubscriptionSelector,
} from '@monetization/service';
import { motion, AnimatePresence } from 'framer-motion';
import { ISubscriptionTriggerProps } from './subscription-trigger';
import { useGetUserSubscriptionsQuery } from '@lib/redux/api/monetization-api';
import { useAppSelector } from '@lib/redux/store';
import { useAuthSession } from '@lib/hooks/use-auth-session';

interface Props extends Pick<ISubscriptionTriggerProps, 'triggeredFrom'> {
  onClose: (reason: 'cancel' | 'completion') => void;
  onRefetchSubscriptions: VoidFunction;
  onPaymentSuccess: VoidFunction;
  creatorName: string;
  heroLabel?: string;
  creator_id: string;
  product_ids?: string[];
}
export const SubscriptionContent = (props: Props) => {
  const { isAuthenticated } = useAuthSession();
  const [selectedProduct, setSelectedProduct] = React.useState<ICreatorProduct | null>(null);
  const handleProductSelection = (product: ICreatorProduct) => setSelectedProduct(product);
  const user = useAppSelector((state) => state.user.user);
  const [query] = useState<ICreatorProductQueryParam>({
    creator_id: props.creator_id,
    page: 1,
    pageSize: 10,
  });
  const { data: subscriptionData } = useGetUserSubscriptionsQuery(
    { ...query, user_id: user.id, creator_id: props.creator_id },
    {
      skip: !user?.id || !props.creator_id || !isAuthenticated,
      selectFromResult: ({ data, ...params }) => {
        return {
          data: {
            ...data,
            user_subscriptions: userSubscriptionSelector.selectAll(
              data ? data.user_subscriptions : userSubscriptionAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
      refetchOnMountOrArgChange: true,
    },
  );

  const userSubscription = useMemo(
    () => subscriptionData?.user_subscriptions[0] || null,
    [subscriptionData],
  );

  return (
    <div className="w-full relative rounded-none rounded-t-[26px] md:rounded-b-[26px] overflow-hidden">
      <AnimatePresence>
        {!selectedProduct && (
          <motion.div
            className="scrollbar-none overflow-hidden"
            key="content-base"
            animate="visible"
            exit="exit">
            <SubscriptionContentBase
              {...props}
              onSelectProduct={handleProductSelection}
              userSubscription={userSubscription}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {selectedProduct && (
          <motion.div
            className="scrollbar-none overflow-hidden"
            key="product-selected"
            animate="visible"
            exit="exit">
            <SubscriptionProductSelected
              userSubscriptionForComputation={userSubscription}
              canShowCancelSubscriptionBtn={false}
              canShowManageSubscriptionBtn
              product={selectedProduct}
              onBack={() => setSelectedProduct(null)}
              onClose={props.onClose}
              onRefetchSubscriptions={props.onRefetchSubscriptions}
              onPaymentSuccess={props.onPaymentSuccess}
              forceShowBackBtn
              enableUpgradeOrDowngradePlan
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
