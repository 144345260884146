import { ICreatorProduct, IUserSubscription } from '@monetization/service';
import React, { useMemo } from 'react';
import { ChevronRight } from 'lucide-react';
import { Button } from '@ui/components/ui/button';
import { SubscriptionProductIcon } from './subscription-product-icon';
import { twMerge } from 'tailwind-merge';
import { TextWithLinks } from '@ui/components/moleculas/message-link';

interface Props {
  product: ICreatorProduct;
  tierOrder: number;
  isActive: boolean;
  showDescription: boolean;
  onSelectProduct: (product: ICreatorProduct & {}) => void;
}
export const SubscriptionProductCard = ({
  product,
  tierOrder,
  isActive,
  showDescription,
  onSelectProduct,
}: Props) => {
  const bgColorMap = {
    active: 'bg-[#343840]',
    inactive: 'bg-white',
  };

  const borderColorMap = {
    active: '',
    inactive: 'border border-[#343840]',
  };

  const textColorMap = {
    active: 'text-white',
    inactive: '!text-[#343840]',
  };

  const bgColor = useMemo(() => bgColorMap[isActive ? 'active' : 'inactive'], [isActive]);
  const borderColor = useMemo(() => borderColorMap[isActive ? 'active' : 'inactive'], [isActive]);
  const textColor = useMemo(() => textColorMap[isActive ? 'active' : 'inactive'], [isActive]);

  return (
    <div
      onClick={() => onSelectProduct(product)}
      className={twMerge(
        'rounded-[20px] p-4 flex flex-col cursor-pointer gap-4',
        bgColor,
        borderColor,
        textColor,
      )}>
      {isActive && (
        <div
          className={`border px-2 py-1 rounded-full text-xs whitespace-nowrap w-fit`}
          style={{ color: textColor, borderColor: borderColor }}>
          Active Plan
        </div>
      )}
      <div className="flex items-center justify-between w-full">
        <div className="flex gap-4 items-center">
          <SubscriptionProductIcon product={product} tierOrder={product.tier_order || tierOrder} />
          <div className="space-y-1">
            <p className="text-inherit font-semibold lg:text-lg leading-none capitalize">
              {product.product_name}
            </p>
            <p className="text-inherit text-xs">
              <span className="text-md">${product.price}/month</span>
            </p>
          </div>
        </div>

        <Button variant="ghost" size="icon" className="rounded-full hover:bg-gray-200/30">
          <ChevronRight />
        </Button>
      </div>
      {showDescription && (
        <p className={twMerge('text-[.8rem]', isActive ? 'text-white' : 'text-[#2D3139]')}>
          <TextWithLinks text={product.description} useCase="messaging" preventClickThrough />
        </p>
      )}
    </div>
  );
};
