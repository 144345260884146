import React from 'react';
import { SubscriptionProductSelected } from './subscription-product-selected';
import { IUserSubscription } from '@monetization/service';

interface Props {
  canShowManageSubscriptionBtn: boolean;
  canShowCancelSubscriptionBtn: boolean;
  userSubscription: IUserSubscription;
  overviewClassName?: string;
  onClose: (reason: 'cancel' | 'completion') => void;
  onRefetchSubscriptions: VoidFunction;
  onPaymentSuccess: VoidFunction;
}
export const SubscriptionManagement = ({
  canShowManageSubscriptionBtn,
  canShowCancelSubscriptionBtn,
  userSubscription,
  overviewClassName,
  onClose,
  onRefetchSubscriptions,
  onPaymentSuccess,
}: Props) => {
  return (
    <SubscriptionProductSelected
      canShowCancelSubscriptionBtn={canShowCancelSubscriptionBtn}
      canShowManageSubscriptionBtn={canShowManageSubscriptionBtn}
      userSubscription={userSubscription}
      product={userSubscription.creator_product}
      onClose={onClose}
      onBack={() => {}}
      onRefetchSubscriptions={onRefetchSubscriptions}
      onPaymentSuccess={onPaymentSuccess}
      overviewClassName={overviewClassName}
    />
  );
};
