import AvatarGroupPlaceholder from '@assets/avatar-group-placeholder.png';

interface Props {
  creatorName: string;
}

export const CMSSubscriptionEnticementHero = ({ creatorName }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center px-8">
      <h1 className="text-2xl font-bold text-white text-center">Unlock Exclusive Content</h1>
      <p className="text-center text-white">
        Gain access to premium content curated by{' '}
        <span className="font-semibold italic text-pretty text-primary">{creatorName}</span>
      </p>
      <div className="-space-x-4 flex mt-10">
        <img src={AvatarGroupPlaceholder.src} alt="group of fans avatars" style={{ height: 45 }} />
      </div>
      <p className="text-center text-white text-xs mt-2 max-w-[300px]">
        All membership plans below provide access to this content.
      </p>
    </div>
  );
};
